import React, { useCallback, useEffect, useState } from 'react'
import styleVistaVideo from "../../../../theme/entrenamiento/componentes/VistaVideo.module.css";
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../../shared/UI/Layout';
import Loading from '../../../../vista/components/Loading';
import { useAppSelector } from '../../../../redux/store';
import useRegistroEntrenamiento from '../../../../vista/rutasPublicas/rutasPrivadasMy/entrenamiento/repository/useRegistroEntrenamiento';
import { useReadingExcercise } from '../../hooks';


function ResultScreen() {
  const emailUsuario = useAppSelector((state) => state.auth.email);
  const userId = useAppSelector((state) => state.auth.userId);
  const idEntrenamiento = useAppSelector((state) => state.entrenamiento.current_entrenamiento.id);
  const indiceSesion = useAppSelector((state) => state.entrenamiento.current_sesion);
  const indiceActividad = useAppSelector((state) => state.entrenamiento.current_actividad);
  const lenghtActividades = useAppSelector((state) => state.entrenamiento.current_actividades?.length);
  const location = useLocation();
  const navigate = useNavigate();
  const [ppm, setPpm] = useState(null);
  const [comprehension, setComprehension] = useState(null);
  const [readingId, setReadingId] = useState(null);
  const {
    actualizarRegistroEntrenamiento,
  } = useRegistroEntrenamiento();
  const [_, __, addReadingRecord] = useReadingExcercise();
  useEffect(() => {
    const { search } = location
    const queryParams = new URLSearchParams(search);
    const ppm = queryParams.get("ppm") || "";
    const comprehension = queryParams.get("comprehension") || "";
    const readingId = queryParams.get("readingId") || "";
    setPpm(Math.round(parseFloat(ppm)));        
    setComprehension(Math.round(parseFloat(comprehension) * 100));
    setReadingId(readingId);
  }, [location])

  const saveInformationAndRedirect = useCallback(async () => {
    const data = {
      ppm,
      comprehension,
      readingId,
      fecha_lectura: new Date().toISOString(),
    }
    await addReadingRecord(data);
    actualizarRegistroEntrenamiento(emailUsuario, userId, idEntrenamiento, indiceSesion + 1, indiceActividad + 1);
    navigate(lenghtActividades === 1 ? `/my/entrenamiento/${idEntrenamiento}` : `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`);
  }, [actualizarRegistroEntrenamiento, addReadingRecord, comprehension, emailUsuario, idEntrenamiento, indiceActividad, indiceSesion, lenghtActividades, navigate, ppm, readingId, userId])
  return (
    <Layout title='Resultado'>
      {/* Muestra “Loading” si todavía no hay datos numéricos válidos o si falta el readingId */}
      {(
        (ppm === null || isNaN(ppm)) ||
        (comprehension === null || isNaN(comprehension)) ||
        !readingId
      ) && <Loading isOpen />}
  
      {/* Muestra resultados si ppm y comprehension son números (pueden ser 0) y hay readingId */}
      {(ppm !== null && !isNaN(ppm) &&
        comprehension !== null && !isNaN(comprehension) &&
        readingId
      ) && (
        <React.Fragment>
          <p className={styleVistaVideo.descripcion}>
            ¡Excelente trabajo en tu lectura de hoy!
          </p>
          <p className={styleVistaVideo.descripcion}>
            Velocidad de lectura: {ppm} PPM
          </p>
          <p className={styleVistaVideo.descripcion}>
            Tu comprensión: {comprehension}%
          </p>
          <p className={styleVistaVideo.descripcion}>
            Recuerda que cada día es una oportunidad para mejorar
          </p>
          <button
            id='button_rosado_default'
            style={{ width: '80%', height: '7vh', margin: '5%' }}
            onClick={saveInformationAndRedirect}
          >
            Finalizar
          </button>
        </React.Fragment>
      )}
    </Layout>
  );
}

export default ResultScreen