import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { reportWebVitals } from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFoundPage from './vista/NotFoundPage';
import JuegoFechas from './vista/rutasPublicas/rutasPrivadasMy/juegos/pages/calculo/juegoFechas/JuegoFechas';
import { store } from './redux/store';
import App from './vista/rutasPublicas/App';
import JuegoCodigodePalabrasPage from './vista/rutasPublicas/programas/pages/codigoPalabras/JuegoCodigodePalabrasPage';
import JuegoRepaso from './vista/rutasPublicas/rutasPrivadasMy/juegos/pages/calculo/juegoRepaso/juegoRepaso';
import AdminEnvioCorreo from './vista/rutasPublicas/admin/AdminEnvioCorreo';

function Redireccionar() {
  if (window.location.pathname.startsWith('/programas')) {
    // Si es el subdominio de la aplicación, muestra la PWA
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/programas/fechas" element={<div className='divAllView'>
            <JuegoFechas />
          </div>}/>
          <Route path="/programas/repaso" element={<div className='divAllView'>
            <JuegoRepaso />
          </div>}/>
          <Route path="/programas/envioCorreo" element={<div className='divAllView'>
            <AdminEnvioCorreo />
          </div>}/>
          <Route path="/programas/CodigoPalabras/:limit" element={<div className='divAllView'>
              <JuegoCodigodePalabrasPage />
            </div>}/>
          <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
      </BrowserRouter>
    );
  } else {
    return <BrowserRouter><App /></BrowserRouter>;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Redireccionar />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
