import { useState } from "react";
import estiloLanding from "../rutasPrivadasMy/entrenamiento/theme/landing.module.css"
import { useToast } from "../../../generalUtils/useToast";

interface Props {
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setMensaje?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdminEnvioCorreo = ({ setLoading = () => {}, setMensaje = () => {} }: Props) => {
  const [correos, setCorreos] = useState("");

  const { presentMensajePersonalizado, presentMensajePersonalizadoSquareCenter } = useToast();

  
  const handleEnviarCorreo = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (correos.trim()) {
      try {
        // Construir la URL de la Cloud Function
        const url = `https://us-central1-supercerebros-produccion.cloudfunctions.net/enviarCorreoAdmin`;

        // Realizar la petición POST enviando el correo en el body
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ correos: correos.trim() }),
        });

        if (response.ok) {
          const data = await response.json();
          presentMensajePersonalizadoSquareCenter(data.message, 4);
          setMensaje(true);
        } else {
          const errorText = await response.text();
          presentMensajePersonalizado(`Error: ${errorText}`, 4);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error al llamar la Cloud Function:", error);
        presentMensajePersonalizado("Hubo un error al procesar tu solicitud.", 4);
        setLoading(false);
      }
    } else {
      setLoading(false);
      presentMensajePersonalizado("Por favor, ingresa un correo.", 4);
    }
  };

  return (
    <div id="enviarCorreo" className={estiloLanding.cajaMensaje_cortesia}>
      <h2 className={estiloLanding.tituloMorado} style={{ padding: "0 10%", margin: "5% 0" }}>
        Enviar correos
      </h2>
      <form onSubmit={handleEnviarCorreo} style={{ display: "grid" }}>
        <p className={estiloLanding.campoObligatorio}>Lista de correos electrónicos (uno por línea)*</p>
        <textarea
          placeholder="Pega aquí los correos, cada uno en una línea"
          value={correos}
          onChange={(e) => setCorreos(e.target.value)}
          className="inputPersonalizado2"
          style={{ width: "100%", height: "150px" }} 
        ></textarea>
        <button
          type="submit"
          className="buttonMorado"
          style={{ margin: "0 auto", width: "fit-content", padding: "2% 15%" }}
        >
          ENVIAR CORREOS
        </button>
      </form>
    </div>
  );
};

export default AdminEnvioCorreo;
